import { Card } from 'common/components/card.tsx';
import { RadioSlider } from 'common/components/radioSlider.tsx';
import { useEffect, useState } from 'preact/hooks';
import { Coin, CoinStatus, useCoinById } from 'tokens/token.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { BuyTokenWidget } from 'tokens/components/buyTokenWidget.tsx';
import { SellTokenWidget } from 'tokens/components/sellTokenWidget.tsx';
import { useTranslation } from 'i18n';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import WebApp from '@twa-dev/sdk';
import { Address } from '@ton/core';
import { displayTonNanoValueInDollars, formatPrice } from 'tokens/utils.ts';
import { fetchTonPrice, useTon } from 'main/main.store.ts';
import { GoldShareCoinButton } from 'tokens/components/goldShareCoinButton.tsx';
import { TokenMarketCap } from 'tokens/components/tokenMarketCap.tsx';
import { LiquidityProgress } from 'tokens/tabs/liquidityProgress.tsx';
import { ChartWithControl } from 'tokens/components/chartWithControl.tsx';
import { TransactionHistory } from 'tokens/components/transactionHistory.tsx';

const tokenTradeTabs = {
    buy: BuyTokenWidget,
    sell: SellTokenWidget,
};

export const TokenTradeTab = ({ tokenId }: { tokenId: Coin['id'] }) => {
    const { price } = useTon();

    useEffect(() => {
        if (!price) {
            fetchTonPrice();
        }
    }, [price]);

    const { coin, coinBalance } = useCoinById();
    const [tradeType, setTradeType] = useState<'buy' | 'sell'>('buy');
    const { t } = useTranslation();

    if (!coin) {
        return <Skeleton size="lg" />;
    }

    const TradeWidget = tokenTradeTabs[tradeType];

    const redirectToPocketFi = () => {
        WebApp.openTelegramLink(
            `https://t.me/pocketfi_bot/swap?startapp=swapFrom-${Address.parse(coin.address).toString({ urlSafe: true, bounceable: false })}`
        );
    };

    return (
        <>
            <div className="bg-[#002366] flex flex-col gap-[1px] border-2 border-black pb-2">
                <div className="bg-blue flex gap-2.5 items-start p-2.5">
                    <img src={coin.imageUrl} alt={coin.imageName} className="w-20 aspect-square" />
                    <div className="flex flex-col gap-2.5">
                        <div className="flex items-center gap-1">
                            <span className="text-white">{coin.name}</span>
                            <span className="text-sm text-yellow font-normal">{coin.symbol}</span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-xs text-white/80 font-normal">
                                {t('market-cap')}
                            </span>
                            <TokenMarketCap
                                onlyNumber
                                value={displayTonNanoValueInDollars(coin.marketCap, price)}
                            />
                        </div>
                    </div>
                    <div className="flex ml-auto">
                        <GoldShareCoinButton />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-[1px] items-center">
                    <div className="bg-blue flex flex-col gap-0.5 items-start p-2.5 flex-1 border-t-0">
                        <div className="flex flex-col items-start">
                            <span className="text-xs text-white/80 font-normal">
                                {t('token.price')}
                            </span>
                            <div className="flex items-center">
                                <span
                                    className="text-white"
                                    dangerouslySetInnerHTML={{
                                        __html: formatPrice(coin.currentPrice ?? '0', price),
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                    {/*<div className="bg-blue flex flex-col gap-0.5 items-start p-2.5 flex-1 border-t-0">*/}
                    {/*    <div className="flex flex-col items-start">*/}
                    {/*        <span className="text-xs text-white/80 font-normal">*/}
                    {/*            {t('token.liquidity')}*/}
                    {/*        </span>*/}
                    {/*        <div className="flex items-center">*/}
                    {/*            <span className="text-white">*/}
                    {/*                {coin.tonLiqCollected*/}
                    {/*                    ? new Intl.NumberFormat('en', {*/}
                    {/*                          currency: 'USD',*/}
                    {/*                          style: 'currency',*/}
                    {/*                          notation: 'compact',*/}
                    {/*                      }).format(*/}
                    {/*                          Number(*/}
                    {/*                              displayNanoValue(*/}
                    {/*                                  coin.tonLiqCollected,*/}
                    {/*                                  coin.decimals*/}
                    {/*                              )*/}
                    {/*                          ) * price ?? 0*/}
                    {/*                      )*/}
                    {/*                    : 'n/a'}*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <LiquidityProgress coin={coin} />
            <Card className="!bg-white !p-3">
                <ChartWithControl coin={coin} />
            </Card>
            {coin.status === CoinStatus.LiquiditySent ? (
                <BigColorButton className="whitespace-nowrap" onClick={redirectToPocketFi}>
                    {t('token.swap-on-pocketfi')}
                </BigColorButton>
            ) : (
                <>
                    <Card className="flex flex-col gap-3 !p-4 bg-gradient-to-b from-white/40 to-transparent !bg-blue">
                        <RadioSlider
                            value={tradeType}
                            onChange={setTradeType}
                            options={[
                                {
                                    value: 'buy',
                                    label: (
                                        <div className="flex items-center gap-1.5">
                                            <img
                                                src="/green_ball.png"
                                                alt=""
                                                className="w-4 h-4 -mt-0.5"
                                            />
                                            <span>{t('token.buy')}</span>
                                        </div>
                                    ),
                                },
                                {
                                    value: 'sell',
                                    label: (
                                        <div className="flex items-center gap-1.5">
                                            <img
                                                src="/red_ball.png"
                                                alt=""
                                                className="w-4 h-4 -mt-0.5"
                                            />
                                            <span>{t('token.sell')}</span>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                        <TradeWidget coin={coin} coinBalance={coinBalance ?? 100} />
                    </Card>
                    <TransactionHistory tokenId={tokenId} />
                </>
            )}
        </>
    );
};
