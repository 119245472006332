import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { Card } from 'common/components/card.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchReferralsStat, useReferralsStats } from 'frens/frens.store.ts';
import { ReferralsStatsTable } from 'frens/referralsStatsTable.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useUser } from 'user/user.store.ts';
import { generateReferralLink, openShareWithFriendsLink } from 'frens/utils.ts';
import { CopyButton } from 'common/components/copyButton.tsx';
import { useTranslation } from 'i18n';

export const FrensPage = () => {
    useBackButton({
        from: '/frens',
        callback: navigation.mainPage,
    });
    const { t } = useTranslation();
    const { user } = useUser();

    useAutoFetch(fetchReferralsStat);

    const { stats } = useReferralsStats();

    const referralLink = generateReferralLink({ user });

    return (
        <div className="flex flex-col items-center w-full gap-2">
            <div className="flex flex-col items-center w-full gap-5 mb-3">
                <img
                    src="/frens_icon_2.png"
                    alt="frens"
                    className="w-[4.5rem] aspect-square mt-3"
                />
                <div className="w-full flex flex-col gap-2 px-5">
                    <TextWithShadow className="italic text-center">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('frens.title')
                                    .toUpperCase()
                                    .replace(
                                        /<>(.*?)<\/>/gi,
                                        `<span class="font-bold text-[#A5F74D] relative">
                                                <svg viewBox="0 0 45 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute top-1/2 -left-[20%] -translate-y-1/2 w-[120%] -z-10">
                                                <path d="M11.0769 4.46429H0L7.61539 13.3929L4.15385 24.1071L10.3846 22.3214V25L22.8462 21.4286L36 24.1071L33.9231 20.5357L43.6154 23.2143L40.1538 15.1786L45 6.25L39.4615 4.46429L41.5385 0L27.6923 3.57143L19.3846 1.78571L18.6923 4.46429L8.30769 1.78571L11.0769 4.46429Z" fill="url(#paint0_linear_7993_45833)" style=""/>
                                                <defs>
                                                <linearGradient id="paint0_linear_7993_45833" x1="22.5" y1="0" x2="22.5" y2="25" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#FF00DD" style="stop-color:#FF00DD;stop-color:color(display-p3 1.0000 0.0000 0.8667);stop-opacity:1;"/>
                                                <stop offset="1" stop-color="#FF0004" style="stop-color:#FF0004;stop-color:color(display-p3 1.0000 0.0000 0.0167);stop-opacity:1;"/>
                                                </linearGradient>
                                                </defs>
                                                </svg>
<!--                                                <img src="/back.svg" alt=""  />-->
                                                $1
                                             </span>`
                                    ),
                            }}
                        />
                    </TextWithShadow>
                    <p className="text-xs text-white text-center">{t('frens.referral-fees')}</p>
                </div>
            </div>
            <RuleCard percent={30} text={t('frens.level1')} />
            <RuleCard percent={7} text={t('frens.level2')} />
            <RuleCard percent={3} text={t('frens.level3')} />
            <div className="flex items-center gap-2 w-full mb-5">
                <BigColorButton
                    variant="yellow"
                    icon={<img src="/arrow_right.gif" alt="open" className="w-6" />}
                    onClick={() => openShareWithFriendsLink(referralLink)}
                >
                    {t('frens.inviteButton').toUpperCase()}
                </BigColorButton>
                <CopyButton text={referralLink}>
                    <BigColorButton
                        variant="yellow"
                        className="aspect-square !w-12 !min-w-[3rem]"
                        textClassName="flex items-center justify-center"
                    >
                        <img src="/content_copy.svg" alt="copy" className="w-6 aspect-square" />
                    </BigColorButton>
                </CopyButton>
            </div>
            {stats ? <ReferralsStatsTable stats={stats} /> : <Skeleton size="lg" height="96px" />}
            {/*<DetailedFrensList />*/}
        </div>
    );
};

const RuleCard = ({ percent, text }: { percent: number; text: string }) => {
    return (
        <Card className="!bg-blue !p-3 flex items-center gap-2">
            <div className="aspect-square w-12 min-w-[3rem] bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200] flex items-center justify-center relative">
                <img
                    src="/hot.png"
                    alt=""
                    className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
                <TextWithShadow className="!text-yellow italic z-10">{`${percent}%`}</TextWithShadow>
                <img
                    src="/frens_rate_chess.gif"
                    alt=""
                    className="absolute h-14 -bottom-1 left-0"
                />
            </div>
            <p className="text-white text-sm">{text}</p>
        </Card>
    );
};
