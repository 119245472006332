import {
    DeviceInfo,
    ConnectRequest,
    ConnectEvent,
    AppRequest,
    WalletResponse,
    WalletEvent,
    RpcRequests,
    TonAddressItemReply,
    CONNECT_EVENT_ERROR_CODES,
    SIGN_DATA_ERROR_CODES,
    SendTransactionRpcResponseSuccess,
} from '@tonconnect/protocol';
import { authorizedFetchPocketfi, pocketfiRoutes } from 'common/utils/pocketFi';

// can't find its definition in the package, copied from the docs
interface TonConnectBridge {
    deviceInfo: DeviceInfo;
    protocolVersion: number;
    isWalletBrowser: boolean;
    connect(protocolVersion: number, message: ConnectRequest): Promise<ConnectEvent>;
    restoreConnection(): Promise<ConnectEvent>;
    send(message: AppRequest<keyof RpcRequests>): Promise<WalletResponse<keyof RpcRequests>>;
    listen(callback: (event: WalletEvent) => void): () => void;
}

class PocketFiBridge implements TonConnectBridge {
    readonly protocolVersion: number = 2;
    readonly isWalletBrowser: boolean = true;

    get deviceInfo(): DeviceInfo {
        return {
            platform: 'browser',
            appName: 'pocketfi',
            appVersion: '1.0.0',
            maxProtocolVersion: this.protocolVersion,
            features: [{ name: 'SendTransaction', maxMessages: 4 }],
        };
    }

    async connect(protocolVersion: number, message: ConnectRequest): Promise<ConnectEvent> {
        const ev: ConnectEvent = await (async () => {
            if (protocolVersion != this.protocolVersion) {
                return {
                    event: 'connect_error',
                    id: this.eventId,
                    payload: {
                        code: CONNECT_EVENT_ERROR_CODES.BAD_REQUEST_ERROR,
                        message: 'protocol version not supported',
                    },
                };
            }
            for (const item of message.items) {
                if (item.name == 'ton_proof') {
                    return {
                        event: 'connect_error',
                        id: this.eventId,
                        payload: {
                            code: CONNECT_EVENT_ERROR_CODES.METHOD_NOT_SUPPORTED,
                            message: 'ton_proof not supported',
                        },
                    };
                }
            }

            const res = await authorizedFetchPocketfi(pocketfiRoutes.tc.connect);
            console.log('pocketfi.tonconnect connect response', res);
            if (!res.ok) {
                return {
                    event: 'connect_error',
                    id: this.eventId,
                    payload: {
                        code: CONNECT_EVENT_ERROR_CODES.UNKNOWN_APP_ERROR,
                        message: res.statusText,
                    },
                };
            }
            const tonAddrReply = (await res.json()) as TonAddressItemReply;

            return {
                event: 'connect',
                id: this.eventId,
                payload: {
                    items: [tonAddrReply],
                    device: this.deviceInfo,
                },
            };
        })();
        this.sendEvent(ev);
        return ev;
    }

    restoreConnection(): Promise<ConnectEvent> {
        return this.connect(this.protocolVersion, {
            manifestUrl: '', // pocketfi actually ignores manifest so it's ok
            items: [{ name: 'ton_addr' }],
        });
    }

    async send(message: AppRequest<keyof RpcRequests>): Promise<WalletResponse<keyof RpcRequests>> {
        if (message.method == 'disconnect') {
            this.sendEvent({
                event: 'disconnect',
                id: this.eventId,
                payload: {},
            });
            return {
                id: message.id,
                result: {},
            };
        }
        if (message.method == 'signData') {
            return {
                id: message.id,
                error: {
                    code: SIGN_DATA_ERROR_CODES.METHOD_NOT_SUPPORTED,
                    message: 'signData not supported',
                },
            };
        }

        // sendTransaction

        const res = await authorizedFetchPocketfi(pocketfiRoutes.tc.send, {
            method: 'POST',
            body: message.params[0], // params[0] is already json-encoded
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log('pocketfi.tonconnect send response', res);
        const { boc } = (await res.json()) as { boc: string };

        return {
            id: message.id,
            result: boc,
        };
    }

    private listeners: Array<(ev: WalletEvent) => void> = [];

    private sendEvent(ev: WalletEvent) {
        this.listeners.forEach((c) => c(ev));
    }

    listen(callback: (event: WalletEvent) => void): () => void {
        this.listeners.push(callback);
        return () => {
            this.listeners = this.listeners.filter((c) => c !== callback);
        };
    }

    private get eventId(): number {
        const key = 'pocketfi-tc.lastEventId';
        const lastEventId = parseInt(localStorage.getItem(key) || '') || 0;
        const eventId = lastEventId + 1;
        localStorage.setItem(key, eventId.toString());
        return eventId;
    }
}

declare global {
    interface Window {
        pocketfi?: {
            jsBridgeKey: 'pocketfi';
            tonconnect: TonConnectBridge;
        };
    }
}

export function initPocketFiBridge() {
    window.pocketfi = {
        jsBridgeKey: 'pocketfi',
        tonconnect: new PocketFiBridge(),
    };
}
