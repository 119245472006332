import 'createToken/components/index.css';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { CreateCoinScreenProps } from 'createToken/createCoin.page.tsx';
import { setCoinTicker, useCreateCoin } from 'createToken/createCoin.store.ts';
import { CreateCoinInput } from 'createToken/components/createCoinInput.tsx';
import { useKeyboardOpen } from 'createToken/hooks.ts';
import { ScreenLayout } from 'createToken/components/screenLayout.tsx';
import { ImageContainer } from 'createToken/components/imageContainer.tsx';
import classNames from 'classnames';
import { useTranslation } from 'i18n';

const maxNameLimit = 8;

export const TickerScreen = ({ goNext }: CreateCoinScreenProps) => {
    const { t } = useTranslation();
    const { ticker } = useCreateCoin();

    const { isKeyboardOpen, handleFocus, handleBlur } = useKeyboardOpen();

    const isValid =
        !!ticker.trim().length && !ticker.trim().includes(' ') && ticker.length <= maxNameLimit;

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        goNext();
    };

    return (
        <ScreenLayout isKeyboardOpen={isKeyboardOpen}>
            <ImageContainer src={'/coin_ticker.gif'} isKeyboardOpen={isKeyboardOpen} />
            <CreateCoinInput
                limit={maxNameLimit}
                onChange={setCoinTicker}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={ticker}
                placeholder={t('createToken.ticker.name').toUpperCase()}
                error={!isValid}
            />

            <BigColorButton
                variant="yellow"
                className={classNames('py-2 transition-all', {
                    [`!h-0 !opacity-0`]: !isValid,
                    [`!h-[68px]`]: isValid,
                })}
                onClick={onSubmit}
                disabled={!isValid}
            >
                <div className="flex items-center gap-1">
                    <TextWithShadow>{t('createToken.next').toUpperCase()}</TextWithShadow>
                    <img src="/arrow_right.gif" alt="" className="h-7" />
                </div>
            </BigColorButton>
        </ScreenLayout>
    );
};
