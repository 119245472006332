import { ColorType, createChart, Range, Time } from 'lightweight-charts';
import { useEffect, useRef } from 'preact/hooks';

type Candle = {
    time: Time;
    open: number;
    high: number;
    low: number;
    close: number;
};

type ChartComponentsProps = {
    data: Candle[];
    onVisibleRangeChange?: (range: Range<Time>) => void;
};

export const ChartComponent = ({ data, onVisibleRangeChange }: ChartComponentsProps) => {
    const chartContainerRef = useRef<HTMLDivElement | null>(null); // Явное указание типа
    const candleStickSeriesRef = useRef<any | null>(null);
    const visibleRangeRef = useRef<Range<Time> | null>(null);
    const chartRef = useRef<any | null>(null);

    useEffect(() => {
        const clientWidth = chartContainerRef.current ? chartContainerRef.current.clientWidth : 0;
        const handleResize = () => {
            chart.applyOptions({ width: clientWidth });
        };

        const container = chartContainerRef.current ?? document.createElement('div');

        const chart = createChart(container, {
            layout: {
                background: { type: ColorType.Solid, color: 'black' },
                textColor: 'white',
            },
            width: clientWidth,
            height: 300,
            timeScale: {
                timeVisible: true,
            },
        });
        chart.timeScale().applyOptions({
            minBarSpacing: 35,
        });

        candleStickSeriesRef.current = chart.addCandlestickSeries({
            upColor: '#26a69a',
            downColor: '#ef5350',
            borderVisible: false,
            wickUpColor: '#26a69a',
            wickDownColor: '#ef5350',
        });
        chartRef.current = chart;

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            chart.remove();
        };
    }, []);

    useEffect(() => {
        const logVisibleRange = () => {
            const visibleRange = chartRef.current.timeScale().getVisibleRange();
            if (visibleRange !== null) {
                if (onVisibleRangeChange) {
                    onVisibleRangeChange(visibleRange);
                }
            }
        };

        chartRef.current.timeScale().subscribeVisibleTimeRangeChange(logVisibleRange);

        return () => {
            chartRef.current.timeScale().unsubscribeVisibleTimeRangeChange(logVisibleRange);
        };
    }, [onVisibleRangeChange]);

    useEffect(() => {
        if (candleStickSeriesRef.current && chartRef.current) {
            visibleRangeRef.current = chartRef.current.timeScale().getVisibleRange();
            candleStickSeriesRef.current.setData(data);
            if (visibleRangeRef.current && data.length) {
                chartRef.current.timeScale().setVisibleRange(visibleRangeRef.current);
            }
        }
    }, [data]);

    return <div ref={chartContainerRef} className="w-full overflow-hidden" />;
};
