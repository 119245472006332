import { FC, JSX } from 'preact/compat';
import { Card } from 'common/components/card.tsx';

type DrawerProps = {
    open: boolean;
    onClose?: () => void;
    closable?: boolean;
    children: JSX.Element | JSX.Element[] | null;
    position?: 'left' | 'right' | 'top' | 'bottom';
    isNew?: boolean;
};

const positionClass = {
    left: 'left-0 top-0 max-w-[90%] h-full',
    right: 'right-0 top-0 max-w-[90%] h-full',
    top: 'top-0 left-0 max-h-[90%] w-full',
    bottom: 'bottom-0 left-0 max-h-[90%] w-full',
};

const closePositionClass = {
    left: '-translate-x-full',
    right: 'translate-x-full',
    top: '-translate-y-full',
    bottom: 'translate-y-full',
};

const openPositionClass = {
    left: 'translate-x-0',
    right: 'translate-x-0',
    top: 'translate-y-0',
    bottom: 'translate-y-0',
};
export const Drawer: FC<DrawerProps> = ({
    open,
    onClose,
    children,
    position = 'right',
    closable = true,
}) => {
    return (
        <div
            className={`fixed z-[100] bg-black/60 transition-opacity h-full w-full top-0 left-0 ${
                open ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
            onClick={closable ? onClose : undefined}
        >
            <Card
                className={`fixed z-50 shadow-lg transform transition-transform ${positionClass[position]} ${open ? openPositionClass[position] : closePositionClass[position]} !bg-blue bg-gradient-to-b from-white/40 to-transparent !p-4`}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </Card>
        </div>
    );
};
