import { ConnectedWallet, TonConnectUI } from '@tonconnect/ui';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import {
    setConnectionModalState,
    setTonConnectConnectionStatus,
} from 'tonConnect/tonConnect.store.ts';
import language from 'i18n';

export const tonConnectUI = new TonConnectUI({
    manifestUrl: 'https://temp-stage.bigpump.app/manifest.json',
    restoreConnection: true,
    buttonRootId: 'ton-connect',
    actionsConfiguration: {
        modals: [],
    },
    language: language.language,
});

export const onTonConnectStatusChange = async (wallet: ConnectedWallet | null) => {
    if (wallet) {
        setTonConnectConnectionStatus(true);
        setConnectionModalState(false);
        await authorizedFetch(routes.tonConnectConnect, {
            method: 'POST',
            body: JSON.stringify({
                publicKey: wallet.account.publicKey,
                address: wallet.account.address,
                type: wallet.appName || wallet.device.appName,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } else {
        setTonConnectConnectionStatus(false);
    }
};
