import { InputLabel } from 'common/form/label.tsx';
import { CurrencyInput } from 'common/components/currencyInput.tsx';
import { Button } from 'common/components/button.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { Coin, sellCoins } from 'tokens/token.store.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { Address } from '@ton/core';
import { parseValue } from 'common/utils/parseValue.ts';
import { createValidator } from 'common/form/validate.ts';
import { z } from 'zod';
import { useTranslation } from 'i18n';
import { WalletConnectionWrapper } from 'tonConnect/walletConnectionWrapper.tsx';
import { useBigPumpSdk } from 'bigPumpSdk/sdk.store.ts';
import classNames from 'classnames';

type SellTokenWidgetProps = {
    coin: Coin;
    coinBalance: number;
};

const validationSchema = z.object({
    amount: z
        .string()
        .min(1, { message: 'validation.invalidNumber' })
        .refine(
            (value) => Number.isFinite(Number(value)) && !Number.isNaN(Number(value)),
            'validation.invalidNumber'
        ),
});

export const SellTokenWidget = ({ coin, coinBalance }: SellTokenWidgetProps) => {
    const [amount, setAmount] = useState('');
    const [coinsAmount, setCoinsAmount] = useState('0');
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const bigPumpSdk = useBigPumpSdk();

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const scrollToBuyButton = () => {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsButtonVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );
        observer.observe(buttonRef.current as Element);
        return () => {
            observer.disconnect();
        };
    }, []);

    const getTonsForCoins = useCallback(
        async (amount: NumericString) => {
            if (!amount || !bigPumpSdk) {
                return;
            }

            const coins = await bigPumpSdk.getTonsForCoins(Address.parse(coin.address), amount);

            setCoinsAmount(
                parseValue(coins, coin.decimals).toLocaleString('en', { maximumFractionDigits: 8 })
            );
        },
        [coin]
    );

    useEffect(() => {
        getTonsForCoins(amount as NumericString);
    }, [amount]);

    const validator = useMemo(() => createValidator(validationSchema), []);

    const validate = async (value: string) => {
        const errors = await validator({
            amount: value,
        });
        if (errors.amount) {
            setError(errors.amount);
            return false;
        }
        setError('');
        return true;
    };

    const onSubmit = async () => {
        try {
            const validationResult = await validate(amount);
            if (!validationResult) {
                return;
            }
            sellCoins(amount as NumericString, coin.address);
        } catch (e) {
            console.log(e);
        }
    };

    const convertValueToNumberWithDot = () => {
        setAmount((prev) => {
            const replacedString = prev.replace(',', '.');
            validate(replacedString);
            return replacedString;
        });
    };

    return (
        <div className="flex flex-col gap-3 ">
            <div className="flex flex-col gap-1.5">
                <InputLabel text={t('buy-sell-token-widget-amount')} />
                <CurrencyInput
                    currency={coin.symbol}
                    value={amount}
                    onChange={setAmount}
                    containerClassName="!bg-blue"
                    balance={Intl.NumberFormat('en', {
                        notation: 'compact',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(coinBalance)}
                    validationError={error}
                    onBlur={convertValueToNumberWithDot}
                />
            </div>
            <div className="flex items-center gap-1.5">
                {[25, 50, 75, 100].map((percent) => (
                    <Button
                        key={percent}
                        variant="blueGradient"
                        className="w-12 h-8 text-xs border-black flex-1"
                        onClick={() => setAmount(String((percent * coinBalance) / 100))}
                    >
                        {percent}%
                    </Button>
                ))}
            </div>
            <p className="text-xs text-blue-sky whitespace-nowrap font-medium font-Wix text-center">
                {t('token.youWillReceive')} ~{coinsAmount} TON
            </p>
            <WalletConnectionWrapper containerClassName="w-full">
                <button
                    ref={buttonRef}
                    id="sell"
                    className="bg-gradient-to-r from-[#C11638] via-[#E94B6B] to-[#C11638] rounded-xl create-token-shadow w-full"
                    onClick={onSubmit}
                >
                    <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex items-center justify-center gap-1.5">
                        <TextWithShadow className="italic">
                            {t('token.sell').toUpperCase()}
                        </TextWithShadow>
                    </div>
                </button>
            </WalletConnectionWrapper>
            <button
                className={classNames(
                    'fixed bottom-[100px] left-7 z-30 bg-gradient-to-r from-[#C11638] via-[#E94B6B] to-[#C11638] rounded-xl create-token-shadow w-[calc(100%-3.5rem)]',
                    { hidden: isButtonVisible }
                )}
                onClick={scrollToBuyButton}
            >
                <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex items-center justify-center gap-1.5">
                    <TextWithShadow className="italic">
                        {t('token.sell').toUpperCase()}
                    </TextWithShadow>
                </div>
            </button>
        </div>
    );
};
