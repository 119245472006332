import { Drawer } from 'common/components/drawer.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTranslation } from 'i18n';
import { Card } from 'common/components/card.tsx';
import { capitalize } from 'lodash-es';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { toUserFriendlyAddress } from '@tonconnect/ui';
import { useEffect } from 'preact/hooks';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonBalance, useUser } from 'user/user.store.ts';
import { displayNanoValue } from 'tokens/utils.ts';
import { Button } from 'common/components/button.tsx';
import { CopyButton } from 'common/components/copyButton.tsx';

type WalletModalProps = {
    isOpen: boolean;
    close: () => void;
};

export const WalletModal = ({ isOpen, close }: WalletModalProps) => {
    const { tonBalance } = useUser();
    const { t } = useTranslation();

    useAutoFetch(fetchTonBalance, 10000);

    useEffect(() => {
        if (isOpen) {
            fetchTonBalance();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!tonConnectUI.account) {
            close();
        }
    }, [tonConnectUI.account]);

    const onDisconnect = () => {
        tonConnectUI.disconnect();
        close();
    };

    return (
        <Drawer open={isOpen} onClose={close} position="bottom">
            {tonConnectUI.account ? (
                <div className="flex flex-col items-stretch gap-3">
                    <TextWithShadow className="italic text-center" tag="p">
                        {t('account')}
                    </TextWithShadow>
                    <Card className="flex items-center justify-between !p-3">
                        <div className="flex flex-col gap-1.5">
                            <span className="text-sm">{capitalize(t('token.balance'))}</span>
                            <span className="text-md">
                                {displayNanoValue(tonBalance ?? 0n, 9, 4)} TON
                            </span>
                            <span className="text-xs">
                                {squeezeAddress(
                                    toUserFriendlyAddress(tonConnectUI.account?.address ?? ''),
                                    4
                                )}
                            </span>
                        </div>
                        <div className="flex flex-col justify-between gap-2">
                            <CopyButton
                                text={toUserFriendlyAddress(tonConnectUI.account?.address ?? '')}
                            >
                                <Button variant="blueGradient" className="border-black">
                                    {t('wallet.copy-address')}
                                </Button>
                            </CopyButton>
                            <Button
                                variant="blueGradient"
                                className="border-black"
                                onClick={onDisconnect}
                            >
                                {t('wallet.disconnect')}
                            </Button>
                        </div>
                    </Card>
                </div>
            ) : (
                <Skeleton size="lg" />
            )}
        </Drawer>
    );
};
