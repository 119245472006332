import { FC, PropsWithChildren } from 'preact/compat';
import { Header } from './header.tsx';

type MainLayoutProps = {
    header?: PropsWithChildren['children'];
    color?: string;
    footer?: PropsWithChildren['children'];
};

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
    children,
    header,
    color,
    footer,
}) => {
    return (
        <div
            className="bg-new-background-primary h-screen w-screen fixed top-0 left-0 overflow-y-auto overflow-x-hidden"
            style={{ backgroundColor: color }}
        >
            <Header />
            {header}
            <div className="p-2.5">{children}</div>
            {footer}
        </div>
    );
};
