import { useUser } from 'user/user.store.ts';
import { useCoinById } from 'tokens/token.store.ts';
import { shareCoin } from 'tokens/utils.ts';

export const GoldShareCoinButton = () => {
    const { user } = useUser();
    const { coin } = useCoinById();

    const onClick = () => {
        if (user && coin) {
            shareCoin(user, coin);
        }
    };
    
    return (
        <button className="border-2 border-black/50 relative" onClick={onClick}>
            <div className="flex items-center justify-center w-8 h-8 bg-yellow box-border border-2 border-b-orange border-r-orange border-t-sand border-l-sand">
                <img src="/share_arrow.svg" alt="" />
            </div>
            <img
                src="/coin.svg"
                alt=""
                className="w-5 h-5 absolute top-0 right-0 translate-x-1/2 -translate-y-1/2"
            />
        </button>
    );
};
