import classNames from 'classnames';
import { useTranslation } from 'i18n';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';

type TokenMarketCapProps = {
    className?: string;
    value: string | number;
    onlyNumber?: boolean;
};

export const TokenMarketCap = ({ className, value, onlyNumber }: TokenMarketCapProps) => {
    const { t } = useTranslation();

    const marketCap = Number(value)
        ? '$' +
          Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits: 1,
          }).format(Number(value))
        : 'n/a';

    if (onlyNumber) {
        return (
            <div className="relative flex items-center justify-center">
                <TextWithShadow
                    tag="p"
                    className="font-ComicSans text-center text-xl bg-gradient-to-r from-[#B0FF5C] to-[#FFB000] inline-block bg-clip-text !text-transparent"
                    shadowWidth={2}
                >
                    {marketCap}
                </TextWithShadow>
                <TextWithShadow
                    tag="p"
                    className="absolute font-ComicSans text-center text-xl bg-gradient-to-r from-[#B0FF5C] to-[#FFB000] inline-block bg-clip-text !text-transparent"
                    shadowColor="transparent"
                >
                    {marketCap}
                </TextWithShadow>
            </div>
        );
    }

    return (
        <div className="flex items-center gap-1">
            <img src="/bucks.gif" alt="usd" className="w-4 min-w-[1rem]" />
            <span className={classNames('text-2xs text-green', className)}>
                {t('market-cap')}: {marketCap}
            </span>
        </div>
    );
};
