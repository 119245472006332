import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/preact';
import { useCallback } from 'preact/hooks';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';

type TonConnectStore = {
    isConnected: boolean;
    isConnectionModalOpened: boolean;
};

export const $tonConnect = map<TonConnectStore>({
    isConnected: false,
    isConnectionModalOpened: false,
});

export const setTonConnectConnectionStatus = action(
    $tonConnect,
    'setTonConnectConnectionStatus',
    (store, status: boolean) => {
        store.setKey('isConnected', status);
    }
);

export const setConnectionModalState = action(
    $tonConnect,
    'setConnectionModalState',
    (store, state: boolean) => {
        store.setKey('isConnectionModalOpened', state);
    }
);

const selectTonConnectConnectionStatus = computed($tonConnect, (store) => store.isConnected);

const selectConnectionModalState = computed($tonConnect, (store) => store.isConnectionModalOpened);

export const useTonConnectConnectionStatus = () => useStore(selectTonConnectConnectionStatus);

export const useConnectionModal = () => {
    const isOpened = useStore(selectConnectionModalState);

    const open = useCallback(() => {
        tonConnectUI.disconnect();
        setConnectionModalState(true);
    }, []);
    const close = useCallback(() => setConnectionModalState(false), []);

    return {
        isOpened,
        open,
        close,
    };
};
