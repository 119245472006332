import { fetchKingOfHill, useKingOfHill } from 'tokens/token.store.ts';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { TickerLabel } from 'tokens/components/tickerLabel.tsx';
import { TokenMarketCap } from 'tokens/components/tokenMarketCap.tsx';
import { TokenCreatedBy } from 'tokens/components/tokenCreatedBy.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { displayTonNanoValueInDollars } from 'tokens/utils.ts';
import { useTon } from 'main/main.store.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { useTranslation } from 'i18n';
import { useTonConnectConnectionStatus } from 'tonConnect/tonConnect.store.ts';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { WalletConnectionWrapper } from 'tonConnect/walletConnectionWrapper.tsx';

export const KingOfHill = () => {
    const { king } = useKingOfHill();
    const { price } = useTon();
    const { t } = useTranslation();
    const isWalletConnected = useTonConnectConnectionStatus();

    useAutoFetch(fetchKingOfHill);

    if (!king && isWalletConnected) {
        return <Skeleton size="lg" height={200} />;
    }

    const navigateToKing = () => {
        if (king) {
            navigation.tokenTrade(king.id)();
        }
    };

    return (
        <div
            className={`border-2 border-blue bg-[url('/windows_xp.webp')] bg-center bg-cover ${isWalletConnected ? 'p-4' : 'px-4 py-10'}`}
            onClick={navigateToKing}
        >
            {!isWalletConnected ? (
                <div className="flex flex-col items-center gap-5">
                    <TextWithShadow className="!text-base italic !text-orange">
                        {t('main.create-your-own-memecoin').toUpperCase()}
                    </TextWithShadow>
                    <TextWithShadow className="text-xl italic !text-orange">
                        {t('main.in-30-sec').toUpperCase()}
                    </TextWithShadow>
                    <WalletConnectionWrapper containerClassName="w-44">
                        <BigColorButton
                            icon={
                                <img
                                    src="/gem.gif"
                                    alt="gem"
                                    className="w-full aspect-square absolute"
                                />
                            }
                            onClick={navigation.createToken}
                            fontSize="sm"
                        >
                            {t('main.start').toUpperCase()}
                        </BigColorButton>
                    </WalletConnectionWrapper>
                </div>
            ) : king ? (
                <>
                    <TextWithShadow
                        shadowWidth={1}
                        className="!text-dark-yellow italic text-center"
                        tag="p"
                    >
                        {t('main.kingOfTheHill').toUpperCase()}
                    </TextWithShadow>
                    <div className="border-2 border-black p-3 flex items-center gap-2 bg-[#005AE0] bg-gradient-to-b from-transparent via-white/30 to-transparent">
                        <img
                            src={king.imageUrl}
                            alt={king.imageName}
                            className="w-[4.5rem] h-[4.5rem]"
                        />
                        <div className="w-full flex flex-col gap-1.5">
                            <div className="flex items-center justify-between">
                                <span className="text-sm text-white">{king.name}</span>
                                <TickerLabel>{king.symbol}</TickerLabel>
                            </div>
                            <TokenMarketCap
                                className="text-oxid-green"
                                value={displayTonNanoValueInDollars(king.marketCap, price)}
                            />
                            <TokenCreatedBy>{king.user.username}</TokenCreatedBy>
                        </div>
                    </div>
                </>
            ) : (
                <Skeleton size="lg" />
            )}
        </div>
    );
};
