import { useState } from 'preact/hooks';
import WebApp from '@twa-dev/sdk';

export const useKeyboardOpen = () => {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const isMobile =
        WebApp.platform === 'ios' ||
        WebApp.platform === 'android' ||
        WebApp.platform === 'android_x';

    return {
        handleFocus,
        handleBlur,
        isKeyboardOpen: isFocused && isMobile,
    };
};
