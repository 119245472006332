import { resetError, useCreateCoin } from 'createToken/createCoin.store.ts';
import { useEffect } from 'preact/hooks';

export const ErrorBubble = () => {
    const { error } = useCreateCoin();

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                resetError();
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [error]);

    return (
        <div className="fixed top-0 left-0 w-screen h-0">
            <div
                className={`absolute bg-red text-white text-xs font-normal rounded-lg px-2.5 py-1.5 ${error ? 'top-3' : '-top-10'} left-1/2 -translate-x-1/2 transition-all duration-300 max-w-[80%] break-words`}
            >
                {error}
            </div>
        </div>
    );
};
