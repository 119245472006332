import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { Card } from 'common/components/card.tsx';
import { ReferralsStats } from 'frens/frens.store.ts';
import { useTranslation } from 'i18n';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { parseValue } from 'common/utils/parseValue.ts';

export const ReferralsStatsTable = ({ stats }: { stats: ReferralsStats }) => {
    const { t } = useTranslation();

    return (
        <Card className="!bg-light-blue bg-gradient-to-b from-white/40 to-transparent p-4 flex flex-col items-center gap-3 w-full">
            <TextWithShadow className="italic">
                {t('frens.stats.title').toUpperCase()}
            </TextWithShadow>
            <div className="grid grid-cols-2 items-center gap-3 w-full">
                <StatCard
                    icon="/diamond.png"
                    value={`${parseValue(stats.total.tonEarned, 9)} TON`}
                    label={t('frens.stats.totalEarned').toUpperCase()}
                />
                <StatCard
                    icon="/ruby_heart.png"
                    value={stats.total.frens}
                    label={t('frens.stats.totalFrens').toUpperCase()}
                />
            </div>
            <div className="bg-blue-sky/30 w-full p-3 grid grid-cols-3">
                <span className="text-2xs border-b border-b-blue-sky pb-2 mb-2">
                    {t('frens.stats.table.frensLvl')}:
                </span>
                <span className="text-2xs border-b border-b-blue-sky pb-2 mb-2 text-center">
                    {t('frens.stats.table.totalInvited')}:
                </span>
                <span className="text-2xs border-b border-b-blue-sky pb-2 mb-2 text-center">
                    {t('frens.stats.table.totalEarned')}:
                </span>
                <span className="text-2xs text-white border-b border-b-blue-sky pb-2 mb-2">
                    1 lvl – 35%
                </span>
                <span className="text-2xs text-white border-b border-b-blue-sky pb-2 mb-2 text-center">
                    {stats.level1.frens}
                </span>
                <span className="text-2xs text-white border-b border-b-blue-sky pb-2 mb-2 text-center">
                    {parseValue(stats.level1.tonEarned, 9)} TON
                </span>
                <span className="text-2xs text-white border-b border-b-blue-sky pb-2 mb-2">
                    2 lvl – 10%
                </span>
                <span className="text-2xs text-white border-b border-b-blue-sky pb-2 mb-2 text-center">
                    {stats.level2.frens}
                </span>
                <span className="text-2xs text-white border-b border-b-blue-sky pb-2 mb-2 text-center">
                    {parseValue(stats.level2.tonEarned, 9)} TON
                </span>
                <span className="text-2xs text-white mb-2">3 lvl – 5%</span>
                <span className="text-2xs text-white mb-2 text-center">{stats.level3.frens}</span>
                <span className="text-2xs text-white mb-2 text-center">
                    {parseValue(stats.level3.tonEarned, 9)} TON
                </span>
            </div>
            <BigColorButton disabled variant="green">
                {t('frens.claimReward')}
            </BigColorButton>
            <p className="text-xs text-white">{`${t('frens.claimRewardCondition')}`}</p>
        </Card>
    );
};

const StatCard = ({
    icon,
    label,
    value,
}: {
    icon: string;
    label: string;
    value: string | number;
}) => {
    return (
        <div className="border border-black bg-gradient-to-r from-[#0062FF] via-[#00C3FF] to-[#0062FF] p-2 flex-1 flex flex-col items-center">
            <div className="flex items-center gap-1">
                <img src={icon} alt="diamond" className="w-5 aspect-square min-w-[1.25rem]" />
                <span className="text-xs">{label}</span>
            </div>
            <TextWithShadow className="italic text-yellow text-center" tag="p">
                {value}
            </TextWithShadow>
        </div>
    );
};
