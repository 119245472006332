import Bugsnag from '@bugsnag/js';
import { render } from 'preact';
import React from 'preact/compat';
import { App } from './app.tsx';
import './index.css';
import registerServiceWorker from 'registerServiceWorker.ts';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { sendReferralCode } from 'frens/frens.store.ts';
import './bugsnag.ts';

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

registerServiceWorker();
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

async function main() {
    // if (
    //     localStorage.getItem('qweqwenuqwjejsdasdasdasdasdas') !== 'true' &&
    //     releaseStage !== 'development'
    // ) {
    //     return;
    // }

    const startParams = parseStartParam();

    if (startParams?.referralCode) {
        await sendReferralCode(startParams.referralCode);
    }

    render(
        <ErrorBoundary>
            <App />
        </ErrorBoundary>,
        document.getElementById('app')!
    );
}

main();
