import { Address } from '@ton/core';

export const cacheWorkerVersion = 'v1.0.0';

export const botName = import.meta.env.VITE_BOT_NAME ?? 'SETUP_BOTNAME_IN_ENV';

export const releaseStage = import.meta.env.VITE_APP_ENV ?? 'development';

export const appVersion = import.meta.env.VITE_APP_VERSION ?? '0.0.1';

export const TON_FUN_MASTER_ADDRESS =
    import.meta.env.VITE_APP_TON_FUN_MASTER_ADDRESS ??
    'EQClNdYKPB8x4bFYXIT_soVQGEXogHnEF-sbQ4wYz2oiVwjZ';

export const TON_FUN_API_URL =
    import.meta.env.VITE_APP_TON_FUN_API_URL ?? 'https://test.tonfun.tech/api/v1';

export const FEE_ADDRESS = Address.parse(
    import.meta.env.VITE_APP_FEE_ADDRESS ?? 'UQCziNJPlKJz82CYgDm5q2lGkGMfSJZ01ZrQdXHiBEJwHlIY'
);

export const TON_API_KEY = '';
