﻿import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/preact';
import { BaseEntityStore } from 'common/constants/types.ts';
import { CoinCreateFormValues } from 'createToken/createToken.page.tsx';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { Address } from '@ton/ton';
import { parseValue } from 'common/utils/parseValue.ts';
import { uniqBy } from 'lodash-es';
import { Time } from 'lightweight-charts';
import { getSdk } from 'bigPumpSdk/sdk.store.ts';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { $user } from 'user/user.store.ts';
import { fromNano } from '@ton/core';

export enum CoinStatus {
    Init = 'init', // created on backend
    Active = 'active', // deployed on chain
    Disabled = 'disable',
    LiquiditySent = 'liquidity-sent', // liquidity sent from the ton fun
}

export type Coin = {
    id: string;
    name: string;
    symbol: string;
    description: string;
    imageUrl: string;
    imageName: string;
    createdAt: string;
    status: CoinStatus; // ???
    user: {
        username: string;
    };
    authorAddress: string;
    updatedAt: string;
    address: string;
    decimals: number;
    tgChannel: null | string;
    tgChat: null | string;
    twitter: null | string;
    website: null | string;
    refId: string;
    chainCreatedAt: string;
    tonBalance: string;
    hotMeasure: string;
    hotMeasureUpdated: string;
    marketCap: string;
    totalSupply: string;
    bclSupply: string;
    bondingCurveProgress: string;
    availableToBuy: string;
    tonLiqCollected: string;
    tonSupply: string;
    currentPrice: string | null;
    bondingCurveProgressTon: string;
};

export enum SortListType {
    Hot = 'hot',
    TopMcap = 'top-mcap',
    New = 'new',
    PocketFi = 'pocketfi',
    MyTokens = 'my-tokens',
}

interface CoinsStore extends BaseEntityStore {
    coins: Coin[];
    search: string;
    searchResults: Coin[];
    isSearchLoading: boolean;
    sortType: SortListType;
    cursor?: string;
}

export const $coins = map<CoinsStore>({
    coins: [],
    search: '',
    searchResults: [],
    isSearchLoading: false,
    sortType: SortListType.Hot,
    isFetched: false,
    isLoading: false,
});

// actions

export const fetchCoinsList = action($coins, 'fetchCoinsList', async (store) => {
    store.setKey('isLoading', true);

    try {
        const { sortType, cursor, coins } = store.get();

        const search = new URLSearchParams({
            sortType,
            limit: '10',
            ...(cursor
                ? {
                      cursor,
                  }
                : {}),
        });

        const response = await authorizedFetch(routes.coins + '?' + search.toString());

        if (response.ok) {
            const data = (await response.json()) as { coins: Coin[]; nextCursor?: string };

            store.setKey('coins', uniqBy([...coins, ...data.coins], 'id'));
            store.setKey('cursor', data.nextCursor);

            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.log('fetchCoinsList error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const setCoinListSearch = action($coins, 'setCoinListSearch', (store, search: string) => {
    store.setKey('search', search);
    if (!search) {
        store.setKey('searchResults', []);
    }
});

export const searchCoins = action($coins, 'searchCoins', async (store) => {
    store.setKey('isSearchLoading', true);
    try {
        const { search } = store.get();

        if (search) {
            const response = await authorizedFetch(routes.coinsSearch + `?query=${search}`);

            if (response.ok) {
                const { coins } = (await response.json()) as { coins: Coin[] };

                store.setKey('searchResults', coins);
            }
        } else {
            store.setKey('searchResults', []);
        }
    } catch (e) {
        console.log('searchCoins error', e);
    } finally {
        store.setKey('isSearchLoading', false);
    }
});

export const setCoinListType = action(
    $coins,
    'setCoinListType',
    (store, sortType: SortListType) => {
        store.setKey('sortType', sortType);
        store.setKey('isFetched', false);
        store.setKey('cursor', undefined);
        store.setKey('coins', []);
        fetchCoinsList();
    }
);

export const createCoin = action(
    $coins,
    'createCoin',
    async (store, data: CoinCreateFormValues) => {
        try {
            const { file, ...metadata } = data;
            const formData = new FormData();
            formData.append('file', file);
            formData.append(
                'meta',
                JSON.stringify({
                    ...metadata,
                    pubKey: tonConnectUI.account?.publicKey,
                })
            );
            const response = await authorizedFetch(routes.coinsCreateCoin, {
                method: 'POST',
                body: formData,
            });

            const bigPumpSdk = getSdk();

            if (response.ok && bigPumpSdk) {
                const { coin } = (await response.json()) as { coin: Coin };
                await bigPumpSdk.deployCoin({
                    coinId: Number(coin.id),
                    authorAddress: Address.parse(tonConnectUI.account!.address),
                    name: coin.name,
                    symbol: coin.symbol,
                    description: coin.description,
                    imageUrl: coin.imageUrl,
                });
            }
        } catch (e) {
            console.log('createCoin error', e);
        }
    }
);

// selectors

const selectCoins = computed($coins, (store) => ({
    ...store,
    canFetchMore: Boolean(store.cursor),
    isFetching: store.isLoading && !store.isFetched,
}));

// hooks

export const useCoinsList = () => useStore(selectCoins);

// ----------------------------------

interface KingOfHillStore extends BaseEntityStore {
    king?: Coin;
}

export const $kingOfHill = map<KingOfHillStore>({
    isFetched: false,
    isLoading: false,
});

// actions

export const fetchKingOfHill = action($kingOfHill, 'fetchKingOfHill', async (store) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.coinsKingOfHill);

        if (response.ok) {
            const data = (await response.json()) as { coin: Coin };

            store.setKey('king', data.coin);
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.error('fetchKingOfHill error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

// selectors

const kingOfHill = computed($kingOfHill, (store) => store);

// hooks

export const useKingOfHill = () => useStore(kingOfHill);

// --------------------------------

export type CoinTransaction = {
    id: string;
    coinId: Coin['id'];
    user: {
        id: string;
        username: string;
    } | null;
    actorAddress: Address;
    tonFees: string;
    totalSupply: string;
    refId: number;
    openPrice: string;
    closePrice: string;
    lt: string;
    txUtime: string;
    createdAt: string;
    type: 'buy' | 'sell';
    coinsAmount: string;
    tonAmount: string;
};

type KLine = {
    startDttm: number;
    open: number | null;
    high: number | null;
    low: number | null;
    close: number | null;
};

type Candle = {
    time: Time;
    open: number;
    high: number;
    low: number;
    close: number;
};

interface CoinStore extends BaseEntityStore {
    coin?: Coin;
    coinBalance?: number;
    isTransactionsLoading: boolean;
    isTransactionsFetched: boolean;
    transactions: CoinTransaction[];
    transactionsCursor?: string;
    serie: Candle[];
}

export const $coin = map<CoinStore>({
    isFetched: false,
    isLoading: false,
    transactions: [],
    serie: [],
    isTransactionsLoading: false,
    isTransactionsFetched: false,
});

// actions

export const fetchCoinPriceById = action(
    $coin,
    'fetchCoinPriceById',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = store.get().coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinPriceById');
            setTimeout(() => fetchCoinPriceById(coinId), 1000);
            return;
        }

        if (bigPumpSdk) {
            try {
                const coinPrice = await bigPumpSdk.getCoinPrice(currentCoin.address);
                store.setKey('coin', {
                    ...currentCoin,
                    currentPrice: String(fromNano(coinPrice)),
                });
            } catch (e) {
                console.log('error while fetching coin price');
            }
        }
    }
);

export const fetchCoinBalanceById = action(
    $coin,
    'fetchCoinBalanceById',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = store.get().coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinBalanceById');
            setTimeout(() => fetchCoinBalanceById(coinId), 1000);
            return;
        }

        if (bigPumpSdk) {
            try {
                const balance = await bigPumpSdk.getUserCoinBalance(currentCoin.address);
                store.setKey('coinBalance', parseValue(balance, 9));
            } catch {
                store.setKey('coinBalance', 0);
            }
        }
    }
);

export const fetchCoinById = action($coin, 'fetchCoinById', async (store, coinId: Coin['id']) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.coinById + `/${coinId}`);

        if (response.ok) {
            const { currentPrice, ...data } = (await response.json()) as Coin;

            const currentCoin = store.get().coin;

            store.setKey('coin', {
                ...data,
                currentPrice: currentPrice ?? currentCoin?.currentPrice ?? null,
            });

            fetchCoinPriceById(coinId);
            fetchCoinBalanceById(coinId);
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.error('fetchKingOfHill error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const fetchCoinTransactions = action(
    $coin,
    'fetchCoinTransactions',
    async (store, coinId: Coin['id']) => {
        store.setKey('isTransactionsLoading', true);

        try {
            const { transactionsCursor, transactions } = store.get();

            const search = new URLSearchParams({
                limit: '10',
                ...(transactionsCursor
                    ? {
                          cursor: transactionsCursor,
                      }
                    : {}),
            });

            const response = await authorizedFetch(
                routes.coinByIdTransaction(coinId) + '?' + search.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    transactions: CoinTransaction[];
                    nextCursor: string;
                };

                store.setKey('transactions', uniqBy([...transactions, ...data.transactions], 'id'));
                store.setKey('transactionsCursor', data.nextCursor);
                store.setKey('isTransactionsFetched', true);
            }
        } catch (e) {
            console.error('fetchCoinTransactions error', e);
        } finally {
            store.setKey('isTransactionsLoading', false);
        }
    }
);

export const fetchKlines = action(
    $coin,
    'fetchKlines',
    async (
        store,
        params: { fromDttm: number; toDttm: number; coinId: string; stepMinute: 1 | 5 }
    ) => {
        try {
            const prevSeries = store.get().serie;

            const leftDttm = prevSeries.length ? (prevSeries[0].time as number) * 1000 : 0;
            const rightDttm = prevSeries.length
                ? (prevSeries[prevSeries.length - 1].time as number) * 1000
                : 0;
            const stepDelta = 3_600_000 * (params.stepMinute > 1 ? 6 : 1);

            const leftDttmWithDelta = leftDttm + stepDelta / 4;
            const rightDttmWithDelta = rightDttm - stepDelta / 4;

            if (
                prevSeries.length > 1 &&
                leftDttmWithDelta < params.fromDttm &&
                rightDttmWithDelta > params.toDttm
            ) {
                return;
            }

            if (!prevSeries.length) {
                params.fromDttm = params.toDttm - stepDelta;
            }

            if (prevSeries.length && params.fromDttm <= leftDttmWithDelta) {
                params.toDttm = leftDttm;
                params.fromDttm = leftDttm - stepDelta;
            }

            if (prevSeries.length && params.toDttm >= rightDttm - stepDelta / 4) {
                params.fromDttm = rightDttm;
                params.toDttm = rightDttm + stepDelta;
            }

            const searchParams = new URLSearchParams({
                fromDttm: String(params.fromDttm),
                toDttm: String(params.toDttm),
                coinId: String(params.coinId),
                stepMinute: String(params.stepMinute),
            });
            const response = await authorizedFetch(
                routes.coinChart + '?' + searchParams.toString()
            );

            if (response.ok) {
                const data = (await response.json()) as { serie: KLine[] };

                const serie = data.serie
                    .filter((candle) => Object.values(candle).every((val) => val))
                    .map((candle) => ({
                        open: parseValue(candle.open ?? 0, 9),
                        close: parseValue(candle.close ?? 0, 9),
                        low: parseValue(candle.low ?? 0, 9),
                        high: parseValue(candle.high ?? 0, 9),
                        time: (candle.startDttm / 1000) as Time,
                    }));

                const newSerie = uniqBy([...prevSeries, ...serie], 'time');
                newSerie.sort((a, b) => (a.time as number) - (b.time as number));

                store.setKey('serie', newSerie);
            }
        } catch (e) {
            console.log('fetchKlines error', e);
        }
    }
);

export const buyCoins = action(
    $coin,
    'buyCoins',
    async (store, amount: NumericString, coinAddress: string) => {
        const sdk = getSdk();

        if (!sdk) {
            console.error('sdk not found on buyCoins');
            return;
        }

        const user = $user.get().user;

        if (!user) {
            console.error('user not fetched on buyCoins');
            return;
        }

        try {
            await sdk.buy({
                coinAddress,
                tons: amount as NumericString,
                userId: user.id,
            });
        } catch (e) {
            console.log('error while buying coins', e);
        }
    }
);

export const sellCoins = action(
    $coin,
    'sellCoins',
    async (store, amount: NumericString, coinAddress: string) => {
        const sdk = getSdk();

        if (!sdk) {
            console.error('sdk not found on sellCoins');
            return;
        }

        const user = $user.get().user;

        if (!user) {
            console.error('user not fetched on sellCoins');
            return;
        }

        try {
            await sdk.sell({
                coinAddress,
                coins: amount,
                userId: user.id,
            });
        } catch (e) {
            console.log('error while selling coins', e);
        }
    }
);

export const clearKlines = action($coin, 'clearKlines', (store) => {
    store.setKey('serie', []);
});

export const clearTransactions = action($coin, 'clearTransactions', (store) => {
    store.setKey('transactions', []);
    store.setKey('transactionsCursor', undefined);
    store.setKey('isTransactionsFetched', false);
});

export const clearCoin = action($coin, 'clearCoin', (store) => {
    store.setKey('coin', undefined);
    store.setKey('coinBalance', undefined);
    store.setKey('transactions', []);
    store.setKey('serie', []);
    store.setKey('isFetched', false);
    store.setKey('isTransactionsFetched', false);
});

// selectors

const selectCoinById = computed($coin, (store) => ({
    ...store,
    canFetchMore: Boolean(store.transactionsCursor),
    isFetching: store.isLoading && !store.isFetched,
    isTransactionsFetching: store.isTransactionsLoading && !store.isTransactionsFetched,
}));

// hooks

export const useCoinById = () => useStore(selectCoinById);
