import { parseValue } from 'common/utils/parseValue.ts';
import { Coin } from 'tokens/token.store.ts';
import { generateReferralLink, openShareWithFriendsLink } from 'frens/utils.ts';
import { User } from 'user/user.store.ts';

export const displayNanoValue = (value: string | number | bigint, decimals = 9, precision = 2) => {
    return parseValue(value, decimals ?? 9).toLocaleString('en-US', {
        maximumFractionDigits: precision,
    });
};

export const displayTonNanoValueInDollars = (value: string | number | bigint, price = 6) => {
    return (parseValue(value, 9) * price).toLocaleString('en-US', {
        maximumFractionDigits: 2,
    });
};

export const shareCoin = (user: User, coin: Coin) => {
    const url = generateReferralLink({
        user,
        params: {
            coinId: coin.id,
        },
    });
    openShareWithFriendsLink(url);
};

export const formatPrice = (price: string, tonPrice: number) => {
    const number = Number(price) * tonPrice;
    if (number > 0.001) {
        return '$' + price;
    }

    function numZeroesAfterPoint(x: number) {
        if (x % 1 == 0) {
            return 0;
        } else {
            return -1 - Math.floor(Math.log10(x % 1));
        }
    }

    const zeroesAfterPoint = numZeroesAfterPoint(number);

    return `$0.0<sub>${zeroesAfterPoint}</sub>${price.slice(2 + zeroesAfterPoint)}`;
};
