import { useEffect } from 'preact/hooks';

export const useAutoFetch = (callback: () => void, interval?: number) => {
    useEffect(() => {
        callback();

        if (interval) {
            const intervalId = setInterval(callback, interval);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, []);
};
