import WebApp from '@twa-dev/sdk';

export function parseStartParam(): null | {
    referralCode: string;
    params?: Record<string, string>;
} {
    const startParam = WebApp.initDataUnsafe.start_param;
    if (!startParam) return null;

    try {
        const keysAndValues = startParam.split('-');
        const referralCode = keysAndValues[0];

        if (!referralCode) {
            return null;
        }

        const paramsString = keysAndValues.slice(1).join('-');
        if (!paramsString) {
            return {
                referralCode,
            };
        }

        const parsedParams = JSON.parse(atob(decodeURIComponent(paramsString)));

        return {
            referralCode,
            params: parsedParams,
        };
    } catch (e) {
        return null;
    }
}
