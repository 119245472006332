import { ForwardedRef, forwardRef, JSX } from 'preact/compat';

type InputProps = Omit<JSX.HTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    onChange?: (value: string) => void;
    className?: string;
};

export const Textarea = forwardRef(
    (
        { onChange, className, onKeyUp, ...props }: InputProps,
        ref: ForwardedRef<HTMLTextAreaElement>
    ) => {
        const classNames = `flex-1 border border-black bg-dark-blue p-2 text-xs placeholder:text-[#8FADE7] text-white ${className}`;
        return (
            <textarea
                {...props}
                onChange={(e) => onChange && onChange(e.currentTarget.value)}
                className={classNames}
                noValidate
                onKeyUp={(e) => {
                    if (e.which === 13) {
                        e.currentTarget.blur();
                    }
                }}
                ref={ref}
            />
        );
    }
);
