import { Route } from 'wouter-preact';
import { initTonConnect } from './tonConnect/init.ts';
import { MainLayout } from 'common/components/mainLayout.tsx';
import { MainPage } from 'main/main.page.tsx';
import { useEffect } from 'preact/hooks';
import { TokenPage } from 'tokens/token.page.tsx';
import { TokenPageMenu } from 'tokens/tokenPageMenu.tsx';
import { TokenTradeTab } from 'tokens/tabs/tokenTradeTab.tsx';
import { TokenChartTab } from 'tokens/tokenChartTab.tsx';
import { TokenAboutTab } from 'tokens/tabs/tokenAboutTab.tsx';
import { TestPage } from 'test/test.page.tsx';
import { onTonConnectStatusChange, tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonPrice } from 'main/main.store.ts';
import { NotificationRow } from 'main/notificationRow.tsx';
import { FrensPage } from 'frens/frens.page.tsx';
import { fetchMe } from 'user/user.store.ts';
import { WalletConnectModal } from 'tonConnect/walletConnectModal.tsx';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { CreateCoinPage } from 'createToken/createCoin.page.tsx';
import { initPocketFiBridge } from 'tonConnect/pocketfi.ts';
import { initBigPumpSdk } from 'bigPumpSdk/sdk.store.ts';

initTonConnect();
initPocketFiBridge();

declare global {
    interface Window {
        tonConnectUI: import('@tonconnect/ui').TonConnectUI;
    }
}

export function App() {
    useEffect(() => {
        tonConnectUI.onStatusChange(onTonConnectStatusChange, (err) => {
            if (err) {
                tonConnectUI.disconnect();
            }
        });

        initBigPumpSdk();

        const startParams = parseStartParam();

        if (startParams?.params) {
            if ('coinId' in startParams.params) {
                navigation.tokenTrade(startParams.params.coinId)();
            }
        }
    }, []);

    useAutoFetch(fetchTonPrice, 60000);
    useAutoFetch(fetchMe);

    return (
        <>
            <Route path="/">
                <MainLayout header={<NotificationRow />}>
                    <MainPage />
                </MainLayout>
            </Route>

            <Route path="/create">
                <CreateCoinPage />
            </Route>

            <Route path="/token/:id/trade">
                {(params) => (
                    <MainLayout footer={<TokenPageMenu tokenId={params.id} />}>
                        <TokenPage tokenId={params.id}>
                            <TokenTradeTab tokenId={params.id} />
                        </TokenPage>
                    </MainLayout>
                )}
            </Route>

            <Route path="/token/:id/about">
                {(params) => (
                    <MainLayout footer={<TokenPageMenu tokenId={params.id} />}>
                        <TokenPage tokenId={params.id}>
                            <TokenAboutTab tokenId={params.id} />
                        </TokenPage>
                    </MainLayout>
                )}
            </Route>

            <Route path="/token/:id/chart">
                {(params) => (
                    <MainLayout footer={<TokenPageMenu tokenId={params.id} />}>
                        <TokenPage tokenId={params.id}>
                            <TokenChartTab />
                        </TokenPage>
                    </MainLayout>
                )}
            </Route>

            <Route path="/frens">
                <MainLayout color="#1A1A1A">
                    <FrensPage />
                </MainLayout>
            </Route>

            <Route path="/test">
                <TestPage />
            </Route>

            <WalletConnectModal />
        </>
    );
}
