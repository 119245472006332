import { navigate } from 'wouter-preact/use-location';
import { notificationSuccess } from './tgUtilt.ts';

export const basicNavigate = (path: string) => {
    navigate(path);
    notificationSuccess();
};

const navigateWithParams = (path: string) => {
    return () => basicNavigate(path);
};

export const navigation = {
    mainPage: navigateWithParams('/'),
    createToken: navigateWithParams('/create'),
    tokenTrade: (tokenId: string) => navigateWithParams(`/token/${tokenId}/trade`),
    tokenChart: (tokenId: string) => navigateWithParams(`/token/${tokenId}/chart`),
    tokenAbout: (tokenId: string) => navigateWithParams(`/token/${tokenId}/about`),
    frens: navigateWithParams('/frens'),
};
