import { Address } from '@ton/ton';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { useState } from 'preact/hooks';
import WebApp from '@twa-dev/sdk';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { Header } from 'common/components/header';
import { useBigPumpSdk } from 'bigPumpSdk/sdk.store.ts';

const hostname = import.meta.env.VITE_SERVER_URL;
const apiPath = '/api/v1';
const apiUrl = `${hostname}${apiPath}`;

type useTelegramButton = {
    text: string;
    color?: string;
    onClick: () => void;
    disable?: boolean;
    load?: boolean;
};

export const telegramButton = ({ text, color, onClick, disable, load }: useTelegramButton) => {
    const mainButton = WebApp.MainButton;

    mainButton.show();
    mainButton.setText(text || 'Confirm');
    mainButton.setParams({
        color: load || disable ? '#0E4C7F' : color || '#146CB6',
        text_color: load || disable ? '#B2B2B2' : '#fff',
    });
    mainButton.onClick(() => !load && !disable && onClick());
    if (load) {
        mainButton.showProgress(true);
    } else {
        mainButton.hideProgress();
    }
    return mainButton;
};

const ImageUpload = () => {
    const [file, setFile] = useState(null);
    const [metadata, setMetadata] = useState({
        name: 'My token',
        symbol: 'Blablbal',
        description: 'Some long history why u should buy this shit',
        pubKey: '88ee50fb8ce10d7c2ff7fab8e58ef81cbd061ba5029992f8349b9e79663eabde',
    });

    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select an image file.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('meta', JSON.stringify(metadata));

        try {
            const response = await fetch(`${apiUrl}/coins/create`, {
                method: 'POST',
                body: formData,
                headers: {
                    telegramRawData: WebApp.initData,
                },
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log('Upload successful:', responseData);
            } else {
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange} />
            <button className="mt-2" onClick={handleUpload}>
                Upload File
            </button>

            <img
                src="https://bigpump.fra1.cdn.digitaloceanspaces.com/stage/tg_image_3811254832.jpeg"
                alt=""
            />
        </div>
    );
};

export const TestPage = () => {
    const bigPumpSdk = useBigPumpSdk();

    async function buy() {
        const jettonMaster = Address.parse(`EQDxmEuPbIxZHd_Y0BN65HKMrs2DNG0CJQD4r8BA8YVLrgev`);

        const amountOfTonSpend = '1' as NumericString;
        const walletBalance = await bigPumpSdk?.getTonWalletBalance();
        const coinsForTon = await bigPumpSdk?.getCoinsForTons(jettonMaster, amountOfTonSpend);

        console.log('coinsForTon', coinsForTon);
        console.log('walletBalance', walletBalance);

        await bigPumpSdk?.buy({
            coinAddress: jettonMaster.toString(),
            tons: amountOfTonSpend,
            userId: '1',
        });
    }

    async function sell() {
        const jettonMaster = Address.parse(`EQDxmEuPbIxZHd_Y0BN65HKMrs2DNG0CJQD4r8BA8YVLrgev`);

        const amountOfCoins = '100000' as NumericString;

        await bigPumpSdk?.sell({
            coinAddress: jettonMaster.toString(),
            coins: amountOfCoins,
            userId: '1',
        });
    }

    async function getCoinsForTon() {
        console.log('tonConnectUI.account', tonConnectUI.account);
        if (!tonConnectUI.account) {
            console.log('please connect');
        }

        const jettonMaster = Address.parse(`EQCin6eoxb2tiGN_jE-7BxlB7XYP6dFhCTHt-zyRPFcfKmhw`);

        const walletBalance = await bigPumpSdk?.getTonWalletBalance();
        const coinsForTon = await bigPumpSdk?.getCoinsForTons(jettonMaster, '1' as NumericString);

        console.log('walletBalance', walletBalance);
        console.log('coinsForTon', coinsForTon);
    }

    async function deployCoin() {
        await bigPumpSdk?.deployCoin(
            {
                coinId: 1,
                authorAddress: Address.parse(tonConnectUI.account!.address),
                name: 'My new first buy experiment',
                symbol: 'Op',
                description: 'op',
                imageUrl:
                    'https://bigpump.fra1.cdn.digitaloceanspaces.com/stage/58592adc_tg_image_3811254832.jpeg',
            },
            {
                userId: '1',
                tons: '0.3' as NumericString,
            }
        );
    }

    async function getDifferentData() {
        // balance in ton
        const balance = await bigPumpSdk?.getTonWalletBalance();

        console.log('balance', balance);

        // ton price
        const tonPrice = await bigPumpSdk?.getTonPrice();

        console.log('ton price', tonPrice);

        // get coins for ton. Нужно передать адрес коина и количество тон. Вернется сколько можно получить монет.
        // Слипейдж уже включен
        // Нужно для отображения в интерфейсе на покупку
        // const coinsForTon = await sdk.getCoinsForTons()

        // то же самое нужно на селл
        // const tonsForCoins = await sdk.getCoinsForTons(Address.parse('EQDxmEuPbIxZHd_Y0BN65HKMrs2DNG0CJQD4r8BA8YVLrgev'), '1' as NumericString)

        // get user coin balance
        const coinBalance = await bigPumpSdk?.getUserCoinBalance(
            'EQDxmEuPbIxZHd_Y0BN65HKMrs2DNG0CJQD4r8BA8YVLrgev'
        );
    }

    return (
        <>
            <Header></Header>
            <div className="mt-8">
                <ImageUpload></ImageUpload>
            </div>

            <div className="mt-8">
                <button onClick={deployCoin}>deploy coin</button>
            </div>

            <div className="mt-8">
                <button onClick={getCoinsForTon}>get coins for ton test</button>
            </div>

            <div className="mt-8">
                <button onClick={buy}>buy coin test</button>
            </div>

            <div className="mt-8">
                <button onClick={sell}>sell coin test</button>
            </div>

            <div className="mt-8">
                <button onClick={getDifferentData}>get getDifferentData</button>
            </div>

            <img
                src="https://bigpump.fra1.cdn.digitaloceanspaces.com/stage/c809466b_DALL%C2%B7E%202024-10-08%2012.18.45.jpg"
                alt=""
            />
        </>
    );
};
