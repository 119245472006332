import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { PropsWithChildren, ReactNode } from 'preact/compat';
import classNames from 'classnames';

type CreateNewTokenButtonProps = {
    onClick?: () => void;
    icon?: ReactNode;
    children: PropsWithChildren['children'];
    type?: HTMLButtonElement['type'];
    variant?: 'green' | 'yellow';
    fontSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg';
    className?: string;
    textClassName?: string;
    disabled?: boolean;
    description?: string;
};

const mapButtonVariants = {
    green: 'from-[#3A8200] via-[#91CB00] to-[#3A8200]',
    yellow: 'from-[#EF7E0D] via-[#E9C214] to-[#EF7E0D]',
};

const mapFontSize = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    md: 'text-md',
    lg: 'text-lg',
};

export const BigColorButton = ({
    icon = null,
    onClick,
    children,
    type = 'button',
    variant = 'green',
    fontSize = 'base',
    className,
    textClassName,
    disabled,
    description,
}: CreateNewTokenButtonProps) => {
    return (
        <button
            className={classNames(
                `bg-gradient-to-r ${mapButtonVariants[variant]} rounded-xl create-token-shadow w-full ${className} relative overflow-hidden`,
                {
                    [`opacity-40`]: disabled,
                }
            )}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            <div className="w-full bg-gradient-to-b from-transparent via-white/40 to-transparent py-3 flex flex-col gap-1.5">
                <div className="flex items-center justify-center gap-1.5">
                    {icon}
                    <TextWithShadow
                        className={`italic ${mapFontSize[fontSize]} ${textClassName} z-10`}
                    >
                        {children}
                    </TextWithShadow>
                </div>
                {description && <p className="text-xs">{description}</p>}
            </div>
        </button>
    );
};
