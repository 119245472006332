import { useConnectionModal } from 'tonConnect/tonConnect.store.ts';
import { Drawer } from 'common/components/drawer.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTranslation } from 'i18n';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';

export const WalletConnectModal = () => {
    const { t } = useTranslation();
    const { isOpened, close } = useConnectionModal();

    const openTonConnectModal = () => {
        tonConnectUI.openModal();
    };

    const connectPocketFi = () => {
        tonConnectUI.connector.connect({ jsBridgeKey: 'pocketfi' });
    };

    return (
        <Drawer open={isOpened} onClose={close} position="bottom">
            <div className="flex flex-col gap-3 items-center">
                <TextWithShadow className="italic">
                    {t('connect-wallet').toUpperCase()}
                </TextWithShadow>
                <p className="text-sm text-white text-center">{t('connect-wallet.description')}</p>
                <button
                    className="w-full border border-black flex items-center p-3 bg-blue-sky gap-3"
                    onClick={connectPocketFi}
                >
                    <img
                        src="/pocketfi_logo.svg"
                        alt="pocketfi"
                        className="w-9 aspect-square rounded-full border border-black"
                    />
                    <span className="text-sm">PocketFi</span>
                </button>
                <button
                    className="w-full border border-black flex items-center p-3 bg-blue-sky gap-3"
                    onClick={openTonConnectModal}
                >
                    <img
                        src="/ton_connect_logo.png"
                        alt="ton_connect_logo"
                        className="w-9 aspect-square rounded-full border border-black"
                    />
                    <span className="text-sm">TON Connect</span>
                </button>
            </div>
        </Drawer>
    );
};
